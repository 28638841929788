export default [
  {
    title: 'Activation de compte',
    route: 'espace-admin.activateaccount',
    icon: 'CheckSquareIcon',

  },
  {
    title: 'Tableau de bord',
    route: 'espace-admin.dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Type d\'abonnement',
    route: 'espace-admin.typeabonnement',
    icon: 'CheckSquareIcon',

  },
]
